.cards {
  padding-bottom: 20px;
  :global(.slick-list) {
    padding-bottom: 24px;
  }
  :global(.slick-track) {
    display: flex;
    :global(.slick-slide) {
      height: auto;
    }
    :global(.slick-slide > div) {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }
}

.date {
  margin: 20px;
  display: flex;
  align-items: center;
}

.col {
  height: auto;
  margin-bottom: 20px;
}

.card {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 16px 0;
  box-shadow: 0 2px 16px fade(#000, 8);
  background-image: url('../../asset/card-bg.png');
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-color: #FFF;
  margin: 0 20px;
  border-radius: 4px;
  height: 100%;
  &:hover {
    // transform: translateY(-5px);
    box-shadow: 0 6px 16px fade(#000, 12);
  }
  &Image {
    width: 100%;
    height: 184px;
    margin: 16px 0;
    object-fit: contain;
  }
  &Content {
    width: 100%;
    padding: 20px 20px 0 20px;
    font-size: 14px;
    line-height: 26px;
  }
}

@primary-color: #5249ab;