

.cards {
  padding-bottom: 20px;
  :global(.slick-list) {
    padding-bottom: 24px;
  }
  :global(.slick-track) {
    display: flex;
    :global(.slick-slide) {
      height: auto;
    }
    :global(.slick-slide > div) {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }
}

.col {
  height: auto;
  margin-bottom: 20px;
}

.card {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 16px 32px;
  box-shadow: 0 2px 16px fade(#000, 8);
  background-image: url('../../asset/card-bg.png');
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-color: #FFF;
  margin: 0 20px;
  border-radius: 4px;
  height: 100%;
  &:hover {
    // transform: translateY(-5px);
    box-shadow: 0 6px 16px fade(#000, 12);
  }
  &Image {
    width: 240px;
    height: 216px;
    margin-bottom: 16px;
  }
}

// Form
.form {
  width: 100%;
  background-color: #FFF;
}

.formTitle {
  font-size: 32px;
  width: 100%;
  text-align: center;
}

.row {
  display: flex;
  justify-content: center;
}

.formCard {
  padding: 0 16px 32px 16px;
}

.formMenus {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.formMenu {
  margin-right: 10px;
  margin-bottom: 10px;
  max-width: 100%;
}

.formMenu :global(span) {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}
@primary-color: #5249ab;